.section-author {
	font-size: 18px;

	h4 {
		font-size: 22px;
	}
}

.section-author ul {
	line-height: 2;
    word-wrap: break-word;
}

.section-author img {
	width: 100%;
	max-width: 600px;
	margin: 50px auto;
	display: block;
	border: 1px solid black;
}