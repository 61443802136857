.buy-button {
	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 17px;
		text-align: center;
	}

	display: inline-block;
	margin-top: 20px;
	background-color: #FDDC66;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 3px #d8bb51;
	padding: 6px 30px;
	text-transform: uppercase;
	color: #4f482d;
	letter-spacing: 2px;
	outline: none;
	font-weight: 600;
	font-size: 25px;
	transition: .1s box-shadow;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	text-align: center;
    vertical-align: middle;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    line-height: 1.42857143;
    user-select: none;
    text-decoration: none;
    cursor: pointer;
    border: 3px solid #fddc66;

    span {
    	font-weight: 800;
    }
}

.buy-button:hover {
	box-shadow: 0 4px #d8bb51;
	border: 3px solid #d8bb51;
}

.buy-button:focus {
	border: 3px solid #b39b45;
}

.buy-button[disabled] {
	opacity: 0.9;
	box-shadow: none;
	cursor: default;
	color: #7b7070;
}