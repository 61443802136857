.section-next-level {
	margin: 70px 0;
	text-align: center;

	p {
		font-size: 30px;
		max-width: 870px;
		margin-left: auto;
		margin-right: auto;
		line-height: 1.7;
	}
}