
.section-screenshots {
	h1 {
		padding-top: 50px;
	}
}


.section-screenshots__list {
	list-style-type: none;

	display: flex;
	flex-wrap: wrap;
	padding: 0;

	img {
		@media screen and (max-width: $mobile-breakpoint) {
			width: 95%;
		}

		width: 45%;
	    height: 100%;
	    border: 1px solid #ccc;
	    margin: 0 auto;
	}

	li {
		@media screen and (max-width: $mobile-breakpoint) {
			flex: 1 0 100%;
			padding-left: 0;
			padding-right: 0;
		}

		flex: 1 0 45%;
		text-align: center;
		padding: 30px 20px;
		display: flex;
		flex-direction: column;

		div {
		    flex: 1 0 auto;
		    display: flex;
		    align-items: center;
		}

		&:first-child {
			flex: 1 0 100%;

			p {
				font-size: 25px;
			}

			img {
			    border: none;
			}
		}

		p {
			margin: 30px auto 0 auto;
			max-width: 90%;
			font-size: 20px;
			color: rgb(124, 116, 118);
			min-height: 60px;
		}
	}
}
