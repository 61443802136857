.section-free-video__main {
	max-width: 620px;
	margin: 0 auto;
	text-align: center;

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	p {
		font-size: 20px;
	}
}