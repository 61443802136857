.section-what-learn__list {
	font-size: 19px;

	@media screen and (max-width: $mobile-breakpoint) {
		column-count: 1;
	}

	column-count: 2;
	column-gap: 100px;
	line-height: 2;
}
