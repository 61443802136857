.section-video-preview {
	h3.section-heading--skinny {
		margin: 0;
		font-size: 33px;

		text-align: center;
		font-weight: normal;
		text-transform: uppercase;
		letter-spacing: 3px;
		line-height: 1.2;
		color: #333;

		em {
			font-weight: bold;
			color: $primary-blue;
			text-decoration: underline;
		}
	}
}

.section-video-preview__content {
	@media screen and (max-width: $mobile-breakpoint) {
		flex-direction: column;
		margin-top: 70px;
		margin-bottom: 30px;
	}

	margin-top: 100px;
	margin-bottom: 50px;
	display: flex;
	justify-content: space-between;
}

.section-video-preview__text {
	p {
		text-align: center;
		font-weight: normal;
		font-size: 33px;
		margin: 10px 0;
	}

	@media screen and (max-width: $mobile-breakpoint) {
		margin-left: 0;
		margin-top: 15px;
		flex-direction: column;
		flex: 0 1 auto;
	}

	flex: 0 1 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 20px;
	justify-content: center;
}

.section-video-preview__laptop {
	@media screen and (max-width: $mobile-breakpoint) {
		flex: 0 1 auto;
	}

	flex: 0 1 60%;
	background-repeat: no-repeat;
	position: relative;
	overflow: hidden;
	background-size: contain;
	max-width: 600px;
}

.section-video-preview__laptop video {
	width: 76%;
	max-width: 460px;
	left: 12%;
	top: 5%;
	position: relative;
}

.section-video-preview__calendar {
	max-width: 130px;
}