
$column-breakpoint: 840px;

.section-basic-overview__text__item:first-child {
	margin-bottom: 60px;
}

.section-basic-overview {
	@media screen and (max-width: $mobile-breakpoint) {
		margin-top: 10px;
	}

	margin-top: 80px;
	margin-bottom: 80px;

	ul {
		list-style-type: none;
	}
}

.section-basic-overview__img {
	@media screen and (max-width: $column-breakpoint) {
		margin-bottom: 40px;
		display: block;
	}

	display: flex;

	img {
		width: 100%;
		max-width: 680px;
		border: 1px solid #eee;
		align-self: center;
		border-radius: 10px;
		box-shadow: 2px 2px 3px rgba(204, 204, 204, 0.73);
	}
}

.section-basic-overview__img,
.section-basic-overview__text {
	@media screen and (max-width: $column-breakpoint) {
		flex-direction: column;
		flex-basis: auto;
	}

	flex-basis: 0;
    flex-grow: 1;
}

.section-basic-overview__list {
	padding-left: 0;
}

.section-basic-overview__list li {
	display: flex;

	@media screen and (max-width: $column-breakpoint) {
		margin-top: 30px;
		margin-bottom: 30px;
	    flex-direction: column;

	    &:last-child .section-basic-overview__img {
	    	order: -1;
	    }
	}

	@media screen and (min-width: $column-breakpoint) {
		&:first-child {
			margin-bottom: 150px;

			.section-basic-overview__img {
				margin-right: 40px;
			}
		}

		&:last-child {
			.section-basic-overview__img {
				margin-left: 40px;

				img {
					margin-left: auto;
				}
			}
		}
	}
}

.section-basic-overview__text {
	align-self: center;
	flex: 0 1 30%;

	h4 {
		margin-top: 0;
		color: $primary-blue;
		margin-bottom: 5px;
		font-size: 27px;

		&:last-of-type {
			@media screen and (min-width: $column-breakpoint) {
				margin-top: 40px;
			}
		}
	}

	p {
	    font-size: 16px;
	    line-height: 1.6;
	}
}