.section-testimonial-preview {
	margin-top: 80px;
	margin-bottom: 80px;
}

.section-testimonial-preview__main {
	@media screen and (max-width: $mobile-breakpoint) {
		flex-direction: column;
	}

	margin: 0 auto;
	max-width: 600px;
	display: flex;
	color: $primary-blue;
	font-weight: bold;
	text-decoration: none;
	transition: color 0.2s ease;

	&:hover {
	    color: #6c9dec;
	}
}

.section-testimonial-preview__pictures {
	@media screen and (max-width: $mobile-breakpoint) {
		justify-content: center;
	}

	list-style-type: none;
	padding-left: 0;
	display: flex;
	margin-right: 20px;


	li {
		margin-right: 10px;
		width: 50px;
	}

	img {
		border-radius: 50%;
		width: 100%;
		border: 1px solid #ccc;
	}
}
