.section-featured {
	background: #f7f7f7;
}

.section-featured__main {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding-top: 40px;
	padding-bottom: 40px;

	@media screen and (max-width: $mobile-breakpoint) {
		flex-direction: column;
		align-items: center;
		margin-bottom: 40px;
	}
}

.section-featured__details {
	@media screen and (max-width: $mobile-breakpoint) {
		text-align: center;
	}

	@media screen and (min-width: $mobile-breakpoint) {
		margin-left: 20px;
	}

	flex: 0 1 60%;
	flex-grow: 3;

	h2, p {
		margin-top: 0;
		margin-bottom: 0;
	}

	h2 {
		background: linear-gradient(25deg,#2878ff,#c822ff);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 30px;
			margin-bottom: 5px;
		}

		@media screen and (min-width: $mobile-breakpoint) {
			font-size: 46px;
			margin-bottom: 15px;
		}
	}

	p {
		@media screen and (max-width: $mobile-breakpoint) {
			text-transform: initial;
			font-size: 17px;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		@media screen and (min-width: $mobile-breakpoint) {
			font-size: 21px;
		}

	    color: red;

		letter-spacing: 1px;
		font-weight: normal;
		color: rgb(51, 51, 51);
		margin-top: 10px;
	}
}

.section-featured__image {
	@media screen and (max-width: $mobile-breakpoint) {
		flex: 0 1 auto;
		display: flex;
		justify-content: space-around;
		max-width: inherit;
		align-items: center;
		min-height: 100px;
	}

	max-width: 370px;
	flex: 0 1 26%;
	flex-grow: 1;

	img {
		@media screen and (max-width: $mobile-breakpoint) {
			width: 80px;
			min-width: initial;
		}

		@media screen and (min-width: $mobile-breakpoint) {
			min-width: 220px;
			width: 100%;
		}

	    height: 100%;
	}
}