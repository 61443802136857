.section-course-community {
	h1 {
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 18px;
		}
	}
	text-align: center;

	img {
		width: 100%;
	}
}
