.section-price__subtitle {
	font-size: 30px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	font-weight: 100;
}

.section-price__table {
	@media screen and (max-width: $mobile-breakpoint) {
		margin-top: 20px;
		margin-bottom: 20px;
		width: 100%;
		max-width: 350px;
		padding: 2em;
	}

	border: 1px solid #95a8b2;
	color: #78909C;
	font-size: 1em;
	min-height: 420px;
	border-radius: 0.2em;
	padding: 2em;
	text-align: center;
	width: 390px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.section-price__container {
	@media screen and (max-width: $mobile-breakpoint) {
		flex-direction: column;
		align-items: center;
		margin-top: 40px;
	}

	display: flex;
	justify-content: space-around;
	margin-top: 70px;
	margin-bottom: 30px;
}

.section-price__table__label {
	text-transform: uppercase;
	font-size: 32px;
    font-weight: bold;
    margin-top: 0;
}

.section-price__container ul {
	list-style-type: none;
	padding-left: 0;
	font-size: 19px;
	line-height: 2.3;
	font-size: 16px;
	text-align: left;
	margin-top: 40px;
	@media screen and (min-width: $mobile-breakpoint) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 230px;
	}
}

.section-price__container ul span {
	margin-right: 10px;
}

.section-price__table__currency {
    font-size: 32px;
    margin-left: -14px;
}

.section-price__table__price__amount {
	font-size: 82px;
	font-weight: normal;
	letter-spacing: 2px;
	font-weight: 100;
	color: $primary-blue;
}

.section-price__table__price__post-release {
	display: block;
	font-style: italic;
	font-size: 16px;
}

.section-price__table__price__post-release span {
	position: relative;
}

.section-price__table__price__post-release span::after {
	content: '';
	border-bottom: 1px solid red;
	transform: rotate(-10deg);
	width: 100%;
	position: absolute;
	right: 0;
	top: 50%;
}

.section-price__email-signup {
	margin-top: 100px;
	margin-bottom: 50px;
}

.section-price__table {
	.buy-button {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.section-price__info {
	color: $primary-blue;
	font-size: 15px;
	margin: 30px auto 0 auto;
}