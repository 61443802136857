.section-mini-vids {
	text-align: center;

	p {
		font-size: 30px;
		max-width: 870px;
		margin-left: auto;
		margin-right: auto;
	}
}

.section-mini-vids__list {
	@media screen and (max-width: $mobile-breakpoint) {
		justify-content: space-around;
	}

	display: flex;
	list-style-type: none;
	justify-content: space-between;
	padding-left: 0;
	flex-wrap: wrap;

	li {
		margin: 10px 10px 20px 10px;
	}

	video {
		border: 1px solid #ccc;
	}
}