
.section-testimonials {
	padding-bottom: 0;

	.js-line-clamp {
		display: none;
		cursor: pointer;
	}
}

.js-read-more {
	font-style: italic;
	color: #246aec;
	cursor: pointer;
	font-weight: bold;

	&:hover {
		color: #267fa2;
	}
}

.section-testimonials__list {
	@media screen and (max-width: $mobile-breakpoint) {
		margin-top: 10px;
	}

	margin-bottom: 0;
	padding-left: 0;
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;

	li {
		@media screen and (max-width: $mobile-breakpoint) {
			flex: 0 1 auto;
			padding: 5px;
			margin: 5px;
		}

		@media screen and (min-width: $mobile-breakpoint) {
			flex: 1 0 40%;
			padding: 10px;
			margin: 10px;
			margin-bottom: 50px;
		}

		list-style-type: none;
	}

	img {
		width: 50px;
		border-radius: 50%;
	}
}

.section-testimonials__item__main {
	display: flex;

	p {
		margin: 0;
		font-size: 16px;
		line-height: 1.7;
	}
}

.section-testimonials__item__img {
	@media screen and (max-width: $mobile-breakpoint) {
		margin-right: 10px;
	}

	@media screen and (min-width: $mobile-breakpoint) {
		margin-right: 20px;
	}
}

.section-testimonials__list__item-author {
	@media screen and (max-width: $mobile-breakpoint) {
		margin-top: 10px;
		margin-left: 60px;
	}

	font-style: italic;
	font-size: 15px;
	margin-left: 70px;

	a {
		font-weight: bold;
		font-size: 19px;
		text-decoration: none;
		font-style: normal;
	}
}