.section-author-mini {
	margin-bottom: 80px;
}

.section-author-mini__main {
	@media screen and (max-width: $mobile-breakpoint) {
		flex-direction: column;
	}
	display: flex;
	justify-content: center;
	max-width: 700px;
	margin: 0 auto;
	align-items: center;
	background: rgba(238, 238, 238, 0.46);
	border-radius: 5px;

	img {
		width: 100%;
		height: 100%;
		max-width: 140px;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	p {
		padding: 10px;
		margin: 0 0 0 20px;
		font-size: 15px;
		line-height: 1.6;

		a {
			color: inherit;
		}
	}
}