.payment-form-field {
	position: relative;
	width: 100%;
	height: 50px;
	margin: 25px 0;
}

.payment-form #user-email {
	border: none;
	display: block;
	font-family: sans-serif;
	margin: 0;
	padding: 0;
	width: 100%;
	font-size: 16px;
	line-height: 1.2em;
	height: 2.2em;
}

.payment-form .baseline {
	position: absolute;
	width: 100%;
	height: 1px;
	left: 0;
	bottom: 0;
	background-color: #cfd7df;
	transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.payment-form label {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 8px;
	color: #8ba6c1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	transform-origin: 0 50%;
	cursor: text;
	pointer-events: none;
	transition-property: color, transform;
	transition-duration: 0.3s;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.payment-form .input {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
	padding-bottom: 7px;
	color: #32325d;
	background-color: transparent;
}

.payment-form .input::placeholder {
	color: transparent;
	transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.payment-form .input.StripeElement {
	opacity: 0;
	transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
	will-change: opacity;
}

.payment-form .input.focused,
.payment-form .input:not(.empty) {
	opacity: 1;
}

.payment-form .input.focused::placeholder,
.payment-form .input:not(.empty)::placeholder {
	color: #8ba6c1;
}

.payment-form .input.focused + label,
.payment-form .input:not(.empty) + label {
	color: #aab7c4;
	transform: scale(0.85) translateY(-30px);
	cursor: default;
}

.payment-form .input.focused + label {
	color: #24b47e;
}

.payment-form .input.invalid + label {
	color: #ffa27b;
}

.payment-form .input.focused + label + .baseline {
	background-color: #24b47e;
}

.payment-form .input.focused.invalid + label + .baseline {
	background-color: #e25950;
}

.payment-form input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	border-style: none;
}

.payment-container {
	max-width: 850px;
	margin: 10px auto;
	padding: 10px;
}

.payment-methods-container {
	@media screen and (max-width: $mobile-breakpoint) {
		margin-top: 10px;
		flex-direction: column-reverse;
	}
	
	display: flex;
	justify-content: space-between;
}

.payment-form {
	min-width: 300px;
}

.payment-checkout-container {
	align-self: center;
}

.payment-or {
	@media screen and (max-width: $mobile-breakpoint) {
		margin: 30px auto;
	}
	
	margin: 0 80px;
	align-self: center;
	font-weight: bold;
	font-style: italic;
}

.payment-instruction-text {
	text-align: center;
	margin: 40px auto;
}

.payment-stripe-note {
	margin: 30px auto;
	color: #bbb;
}

.payment-coupon-form {
	margin: 30px auto;
	color: #aaa;
	text-align: center;

	form {
		align-items: center;
		display: flex;
		justify-content: center;
	}
	
	input[type="text"] {
		margin: auto 20px;
		font-size: 14px;
		padding: 3px;
	}
	input[type="submit"] {
		padding: 3px 10px;
		font-size: 14px;
	}
}

.payment-form-error-message {
	color: #E25950;
	font-weight: bold;
	margin-top: 40px;
	display: block;
	text-decoration: underline;
}

.payment-form-error-message:empty {
	display: none;
}