.section-quick-overview {
	@media screen and (max-width: $mobile-breakpoint) {
		padding-top: 20px;
	}

	padding-top: 70px;
	text-align: center;
	padding-bottom: 10px;
	margin-bottom: 40px;

	ul {
		@media screen and (max-width: $mobile-breakpoint) {
			flex-wrap: wrap;
			justify-content: space-around;
			font-size: 19px;
		}

		font-size: 21px;
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: space-between;
	}

	li {
		@media screen and (max-width: $mobile-breakpoint) {
			flex: 0 1 50%;
			margin-bottom: 30px;
		}

		list-style-type: none;
		padding: 10px 5px;

		span {
			@media screen and (max-width: $mobile-breakpoint) {
				display: block;
				margin-right: 0;
			}

			margin-right: 15px;
			font-size: 30px;
		}
	}

	p {
		font-style: italic;
		margin: 20px 0;
	}
}