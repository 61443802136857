
.section-video-listing__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style-type: none;

	li {
		flex: 1 0 25%;
		text-align: center;
		margin: 30px 20px;
		display: flex;
		flex-direction: column;
		position: relative;
		min-width: 300px;
		max-width: 350px;

		p {
			width: 100%;
			padding: 7px;
			font-size: 15px;
			font-weight: bold;
		}

		img {
			width: 100%;
			height: 100%;
			border: 1px solid #ccc;
			margin: 0 auto;
		}

		div {
			flex: 1 0 auto;
			display: flex;
			align-items: center;
		}
	}
}

.section-video-listing__list__duration {
	font-style: italic;
}