@import './hljs';

.lessons-list {
	max-width: 680px;
	margin-top: 50px;
	margin-left: auto;
	margin-right: auto;

	.yt-wrapper {
	    position: relative;
	    padding-bottom: 56.25%; /* 16:9 */
	    padding-top: 25px;
	    height: 0;
	    margin-bottom: 50px;
	}

	.yt-wrapper iframe {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}

	iframe {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	ol {
		line-height: 1.8;
	}

	.lessons-list-duration {
		font-style: italic;
		padding-left: 10px;
	}
}

.lesson-course-note {
	a:hover {
		color: blue;
	}

	img {
		max-width: 100%;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	p,
	ol,
	ul {
		line-height: 1.7;
	}

	h1 {
		margin-top: 10px;
	}

	h2, h3, h4, h5, h6 {
		margin-top: 40px;
	}

	code {
		font-size: 16px;
	}

	pre code {
		font-family: 'Source Code Pro', monospace;
		word-break: break-word;
		word-wrap: break-word;
		white-space: pre-wrap;
		line-height: 1.4;
		display: block;
		background: #f7f7f7;
		padding: 1em;
		outline: 1px solid #e2e2e2;
	}

	pre {
		margin-top: 20px;
		margin-bottom: 50px;
	}

	blockquote {
		font-size: 1.2em;
		width: 90%;
		margin: 50px auto;
		font-style: italic;
		color: #555555;
		padding: 1.2em 30px 1.2em 75px;
		border-left: 8px solid #78C0A8 ;
		line-height: 1.6;
		position: relative;
		background: #EDEDED;
	}

	blockquote::before{
		font-family: Arial;
		content: "\201C";
		color: #78C0A8;
		font-size: 4em;
		position: absolute;
		left: 10px;
		top: -10px;
	}

	blockquote::after{
		content: '';
	}

	blockquote span{
		display:block;
		color:#333333;
		font-style: normal;
		font-weight: bold;
		margin-top:1em;
	}

	table {
		margin: 50px 0;
		display: block;
		overflow: auto;
		border-spacing: 0;
		border-collapse: collapse;

		tr {
			background-color: #fff;
			border-top: 1px solid #c6cbd1;
		}

		tr:nth-child(2n) {
		    background-color: #f6f8fa;
		}

		th, td {
			padding: 6px 13px;
			border: 1px solid #dfe2e5;
		}
	}
}

.lesson-next-previous {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;

	p {
		margin: 0;
		&:first-child {
			margin-right: 10px;
		}
	}

	a {
		font-size: 16px;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.js-video-theatre-mode {
	width: 100vw;
	height: 100vh;
	position: absolute;
	left: 0;
	margin: 0;
	padding: 0;

	/* Clear space for the header */
	top: 100px;
}

.lesson-theatre-mode {
	padding: 10px;
	cursor: pointer;
}

.lesson-theatre-mode-active {
	margin-top: 95vh;
}