@import 'home/featured';
@import 'home/video-preview';
@import 'home/screenshots';
@import 'home/mini-vids';
@import 'home/author-mini';
@import 'home/free-video';
@import 'home/basic-overview';
@import 'home/price';
@import 'home/testimonials';
@import 'home/quick-overview';
@import 'home/get-updates';
@import 'home/what-learn';
@import 'home/course-community';
@import 'home/what-get';
@import 'home/video-listing';
@import 'home/sponsors';
@import 'home/author-detailed';
@import 'home/testimonial-preview';
@import 'home/next-level';

html {
    scroll-behavior: smooth;
}

.section-video-preview,
.section-what-learn,
.section-author,
.section-get-updates,
.section-what-get,
.section-sponsors,
.section-faq,
.section-testimonials,
.section-screenshots,
.section-mini-vids,
.section-price,
.section-course-community,
.section-free-video,
.section-video-listing {
	@media screen and (max-width: $mobile-breakpoint) {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	padding: 50px 0 50px;
	border-top: 2px solid #e6e6e6;

	h3:not(.section-heading--skinny) {
		@include section-heading;
	}

	.section-heading--skinny {
		@include section-heading-skinny;
	}
/*
	h4 {

	}
*/
}

.section-faq {
	background-color: #f7f7f7;
	font-size: 18px;
	p:first-child {
		font-weight: bold;
	}
	p:last-child {
		margin-bottom: 50px;
	}
}

.section-author ul,
.section-faq ul,
.section-what-learn__list {
	@media screen and (max-width: $mobile-breakpoint) {
		padding-left: 20px;
	}
}

.section-testimonials {
	border-top: none;
}


.section-video-preview {
	border-top: none;
}




