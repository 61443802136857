
$mobile-breakpoint: 750px;
$container-width: 1280px;
$primary-blue: #2570ec;

@import 'base/font';
@import 'base/base';
@import 'components/footer';
@import 'components/main-button';
@import 'components/nav';
@import 'pages/home';
@import 'pages/pay';
@import 'pages/update-password';
@import 'pages/lessons';
