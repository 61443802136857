
.section-sponsors {
	background-color: #eee;
}

.section-sponsors__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 0;

	li {
		@media screen and (max-width: $mobile-breakpoint) {
			margin: 10px 30px;
			flex: 0 1 40%;
		}

		list-style-type: none;
		margin: 20px 40px;
		flex: 0 1 50%;
		max-width: 120px;
	}

	img {
		filter: grayscale(1);
		width: 100%;
	}
}