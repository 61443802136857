.section-get-updates {
	background-color: #eee;
}

@mixin email-input-styles() {
	font-size: 20px;
}

.section-get-updates__main {
	@media screen and (max-width: $mobile-breakpoint) {
		flex-direction: column;
	}

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	align-items: stretch;

	label {
		flex: 1 100%;
	}

	label.section-get-updates-headline {
		@include section-heading;
	}
	
	label.section-get-updates-headline-alt {
		@include section-heading-skinny;
		margin-bottom: 30px;
	}

	input[type="email"] {
		@include email-input-styles;

		@media screen and (max-width: $mobile-breakpoint) {
			max-width: initial;
			margin-right: 0;
		}

		flex: 0 1 auto;
		width: 100%;
	    max-width: 530px;
		padding: 4px 10px;
		margin-right: 15px;


		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  @include email-input-styles;
		}
		&::-moz-placeholder { /* Firefox 19+ */
		  @include email-input-styles;
		}
		&:-ms-input-placeholder { /* IE 10+ */
		  @include email-input-styles;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  @include email-input-styles;
		}
	}

	input[type="submit"] {
		@media screen and (max-width: $mobile-breakpoint) {
			margin-top: 20px;
		}

		border: 2px solid #dab327;
		background-color: #fddc66;
		padding: 7px;
		text-transform: uppercase;
		color: #4f482d;
		outline: none;
		transition: .1s color;
		text-align: center;
		background-image: none;
		user-select: none;
		font-size: 14px;
		font-weight: bold;
		cursor: pointer;

		&:hover,
		&:focus {
			border: 2px solid #79abe6;
			box-shadow: 2px 2px 2px #333333;
		}

	}
}

.section-get-updates__main input.section-get-updates-btn-alt {
	border: 1px solid #ccc;
	background-color: #fff;
	color: grey;

	&:hover {
		color: #5f5f5f;
		border-color: #5f5f5f;
	}
}