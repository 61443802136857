@mixin container {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: $container-width;
	padding-left: 25px;
	padding-right: 25px;
}

@mixin section-heading {
	@media screen and (max-width: $mobile-breakpoint) {
		margin-bottom: 20px;
		font-size: 28px;
	}

	font-size: 40px;
	font-weight: 800;
	margin: 0 0 15px;
	text-align: center;
	color: #333;
	text-transform: uppercase;
	margin-bottom: 40px;
}

@mixin section-heading-skinny {
	text-align: center;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 3px;
	line-height: 28px;
	font-size: 26px;
	color: #333;
}


* { box-sizing: border-box }

body {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	padding: 0;
	padding-bottom: 10px;
	background: white;
}

a {
	color: $primary-blue;
}

.alert {
	@include container;

	width: $container-width;
	max-width: 95%;
	margin-top: 20px;
	margin-bottom: 1rem;
	word-break: break-word;
	border: 1px solid transparent;
	border-radius: .25rem;
	font-weight: 400;
	line-height: 1.5;
}

.alert__danger {
	background-color: #f2dede;
	border-color: #ebcccc;
	color: #a94442;
}

.alert__success {
	background-color: #dff0d8;
	border-color: #d0e9c6;
	color: #3c763d;
}

.container {
	@include container;
}

input[type="submit"],
button[type="submit"] {
	font-size: 15px;
	padding: 10px;
	cursor: pointer;
}

.form-group {
	margin: 25px 0;
	display: flex;
	flex-direction: column;
	max-width: 350px;

	label {
		margin-bottom: 10px;
	}

	button[type="submit"],
	input[type="text"],
	input[type="password"],
	input[type="email"] {
		padding: 10px;
		border: 1px solid #ddd;
		font-size: 15px;
	}
}
