
.section-what-get {
	background-color: #f7f7f7;
	font-size: 18px;

	p {
		max-width: 870px;
		text-align: center;
		margin: 0 auto;
	}
}


.section-what-get__stats {
	@media screen and (max-width: $mobile-breakpoint) {
		flex-wrap: wrap;
		align-items: baseline;
	}

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0;
	margin: 40px auto;

	li {
		@media screen and (max-width: $mobile-breakpoint) {
			flex: 0 1 48%;
			margin: 5px 2px 15px 2px;
			max-width: initial;
		}

		max-width: 20%;
		list-style-type: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-transform: uppercase;
		margin-left: 5px;
		margin-right: 5px;
	}
}

.section-what-get__stats__figure {
	margin-bottom: 5px;
	font-size: 40px;
	font-weight: 700;
	color: $primary-blue;
}

.section-what-get__stats__description {
	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 13px;
	}
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 2px;
	display: block;
	text-align: center;
	padding-bottom: 3px;
	color: #717171;
}
